import React from "react";
import { useRouter } from "next/router";
import toUpper from "lodash/toUpper";
import toString from "lodash/toString";
import useTranslation from "next-translate/useTranslation";
import loadNamespaces from "next-translate/loadNamespaces";
import { useCompleoCareerPage } from "customHooks/useCompleoCareerPage";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import DefaultLayout from "components/_Layout/Default";
import { hasFoundTheme, hasFoundMetadataTranslation } from "functions/util";
import GoogleAnalytics from "components/Analytics/GA";
import GoogleTagManager from "components/Analytics/GTM";
import MetaPixel from "components/Analytics/MetaPixel";
import Head from "next/head";
import { getCookie } from "cookies-next";

const Home = (props: any) => {
    const cookieIsCustomDomain = getCookie("x-custom-domain");
    const moduleName = "HOME";
    const router = useRouter();
    let { uniqueLink } = router.query;
    const { locale } = router;
    uniqueLink = toUpper(toString(uniqueLink));
    const { data, theme, companyId, languages, company, favicon } = props;
    const themeObject = createTheme(theme);
    const metadata = data.metadado;
    const { lang } = useTranslation(moduleName);

    const language = locale ?? lang;

    const pageResult = useCompleoCareerPage({
        language,
        moduleMetadata: metadata,
        moduleName,
        companyId,
        uniqueLink: toString(uniqueLink),
        router,
        translationNamespace: ["HOME"],
        showEditCareersButton: false,
        languages,
        isCustomDomain: cookieIsCustomDomain === true,
    });

    const pageHeadTitle =
        language === "pt-BR"
            ? `Carreiras ${
                  company?.companyFancyName ?? company?.companyName ?? ""
              }`
            : `Careers ${
                  company?.companyFancyName ?? company?.companyName ?? ""
              }`;

    return (
        <>
            <Head>
                <title>{pageHeadTitle}</title>
                <link rel="icon" href={favicon || "/assets/img/favicon.ico"} />
            </Head>
            <ThemeProvider theme={themeObject}>
                <DefaultLayout>{pageResult}</DefaultLayout>
            </ThemeProvider>
            {props.googleAnalyticsId && (
                <GoogleAnalytics gaTrackingId={props.googleAnalyticsId} />
            )}
            {props.googleTagManagerId && (
                <GoogleTagManager gtmId={props.googleTagManagerId} />
            )}
            {props.metaPixelId && <MetaPixel pixelId={props.metaPixelId} />}
        </>
    );
};

export async function getStaticPaths(props: any) {
    return { paths: [], fallback: "blocking" };
}
export async function getStaticProps(props: any) {
    const { params, locale, ctx } = props;
    const uniqueLink = toUpper(params.uniqueLink);
    const moduleName = "HOME";

    const apiAddressRoot = process.env.NEXT_PUBLIC_REACT_APP_API_ADDRESS;

    const apiRequestBody = {
        modules: [
            {
                module: moduleName,
                type: "careers",
            },
        ],
        uniqueLink: uniqueLink,
        modulesTranslation: [
            {
                module: moduleName,
                type: "careers",
            },
        ],
        language: locale || "pt-BR",
    };

    const apiEndpoint = `${apiAddressRoot}/careers/getalldata`;

    //console.log("body", JSON.stringify({ ...apiRequestBody }));
    const apiResponse = await fetch(apiEndpoint, {
        method: "post",
        body: JSON.stringify({ ...apiRequestBody }),
    });

    const responseJson = await apiResponse.json();
    //console.log("apiResponse", apiResponse);
    if (apiResponse.status !== 200) {
        console.log("apiResponse.status", apiResponse.status);
        if (apiResponse.status === 404) {
            return { notFound: true };
        }

        console.error("Failed to fetch data - error", responseJson);
        throw new Error("Failed to fetch careers page data");
    }

    const hasDraftMetadataAndTranslation = hasFoundMetadataTranslation(
        responseJson?.metadatas,
        responseJson?.translations,
        moduleName,
        "careers"
    );
    const hasDraftTheme = hasFoundTheme(responseJson.theme);

    if (!hasDraftMetadataAndTranslation || !hasDraftTheme) {
        console.error(
            "Failed to fetch data - missing data",
            uniqueLink,
            hasDraftMetadataAndTranslation,
            hasDraftTheme
        );
        throw new Error("Failed to fetch careers page data");
    }

    const languages = responseJson.languages;
    if (locale && languages && !languages.includes(locale)) {
        console.log(
            `${uniqueLink}/home - ${locale} not found in allowed languages ${languages.join(
                ","
            )}`
        );
        return {
            notFound: true,
        };
    }

    const careerPageMetadata = responseJson.metadatas?.filter(
        (m: any) => m.module === "HOME" && m.type === "careers"
    )[0];
    const data = careerPageMetadata?.data ?? {};

    const careerPageTranslation = responseJson.translations?.filter(
        (m: any) => m.module === "HOME" && m.type === "careers"
    )[0];

    const themeDB = responseJson.theme;
    const companyId = responseJson.companyId;
    const company = responseJson.company;
    const googleAnalyticsId = responseJson.googleAnalyticsId;
    const googleTagManagerId = responseJson.googleTagManagerId;
    const metaPixelId = responseJson.metaPixelId;
    return {
        props: {
            data,
            theme: themeDB?.theme ?? {},
            favicon: themeDB?.favicon ?? null,
            languages,
            companyId,
            company,
            googleAnalyticsId: googleAnalyticsId ?? null,
            googleTagManagerId: googleTagManagerId ?? null,
            metaPixelId: metaPixelId ?? null,
            shallow: true,
            ...(await loadNamespaces({
                ...ctx,
                pathname: "/",
                loadLocaleFrom: (lang, ns) =>
                    Promise.resolve(careerPageTranslation.data),
            })),
        },
        revalidate: 300, //seconds
    };
}

export default Home;
